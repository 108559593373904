import React from 'react'
import styled from '@emotion/styled'
import { Segment, Container } from 'semantic-ui-react'
import mq from '@styles/mq'
import theme from '@styles/theme'
import { H1 } from '@styles/Global.styles'
import { isMobile } from '@utils/Helpers'

export const GenericSegment = styled(Segment)`
    &&&&{
        margin: 0;
        padding: 0;
        border: none;
        border-radius: none;
        padding-top: ${props=>props.paddingTop}px;
        padding-bottom: ${props=>props.paddingBottom}px;
        background-color: ${props=>props.bgColor} !important;
        ${props => mq({
            paddingTop: [props.padding.mobile.top, props.padding.mobile.top, props.padding.desktop.top],
            paddingBottom: [props.padding.mobile.bottom, props.padding.mobile.bottom, props.padding.desktop.bottom]
        })}
    }

    ${isMobile() && `h1, h2, h3, h4 { color:  ${theme.mobileTheme.color}} !important;`}
`

export const GenericContainer = styled(Container)`
    &&&&{
        max-width: ${props => props.maxWidth}px !important;
    }
`

export const PlateDesignSegment = styled(Segment)`
    &&&&{
        margin: 0;
        padding: 0;
        padding-top: ${props=>props.paddingTop}px;
        padding-bottom: ${props=>props.paddingBottom}px;
        background: linear-gradient(
            to right,
            ${props=>props.bgLeftColor} 0%,
            ${props=>props.bgLeftColor} 50%,
            #FFFFFF 50%,
            #FFFFFF 100%
          );
    }
`

export const UserAccountWrapperSegment = styled(Segment)`
    &&&&{
        margin: 0;
        padding: 0;
        padding-top: ${props=>props.paddingTop}px;
        padding-bottom: ${props=>props.paddingBottom}px;
        min-height:  calc(100vh - 180px);
        background-color: ${theme.brand.colors.white};
    }
`
export const MobilePlateDesignSegment = styled(Segment)`
    &&&&{
        margin: 0;
        padding-bottom: ${props=>props.paddingBottom}px;
        background-color: ${theme.brand.colors.white}
    }
`
export const PlateDesignContainer = styled(Container)`
`
export const MobilePlateDesignContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 40px;
`
