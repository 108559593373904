import React from 'react'

import {
    GenericContainer,
    GenericSegment
} from './GenericBannerContainer.styles'


interface GenericBannerContainerProps{
    backgroundColor: string | string[]
    children: React.ReactNode
    padding: {
        mobile: {
            top: number,
            bottom: number
        },
        desktop: {
            top: number,
            bottom: number
        }
    }
    removeContainer?: boolean
    containerMaxWidth?: string
}

const MobileGenericBannerContainer = ({backgroundColor = '#FFFFFF', children, padding, removeContainer, containerMaxWidth = '100%'}: GenericBannerContainerProps)=>{
    return <GenericSegment basic bgColor={backgroundColor} padding={padding} >
        {
            removeContainer && children
        }
        {
            !removeContainer &&
            <GenericContainer maxWidth={containerMaxWidth}>
                {children}
            </GenericContainer>
        }
    </GenericSegment>
}

// MobileGenericBannerContainer.defaultProps={
//     paddingTop: 0,
//     paddingBottom: 0,
// }

export default MobileGenericBannerContainer;